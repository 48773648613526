@import url("author.css");
@import url("satoshi.css");

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  .font-title {
    font-family: "Author-Variable", sans-serif;
  }

  .font-body {
    font-family: "Satoshi-Variable", system-ui, sans-serif;
  }

  html {
    @apply font-body;
  }

  /* Headings */
  .heading-xl {
    @apply font-title font-bold text-[64px] leading-[64px] tracking-[-2%];
  }

  .heading-lg {
    @apply font-title font-bold text-5xl tracking-[-2%];
  }

  .heading-md {
    @apply font-title font-semibold text-[32px] leading-[40px] tracking-[-1%];
  }

  .heading-sm {
    @apply font-title font-semibold text-2xl leading-[28px] tracking-[-1%];
  }

  .heading-xs {
    @apply font-title font-semibold text-xl leading-[24px];
  }

  /* Subtitles */

  .subtitle-lg {
    @apply font-body font-medium text-xl;
  }

  .subtitle-md {
    @apply font-body font-medium text-lg;
  }

  .subtitle-sm {
    @apply font-body font-medium text-base;
  }

  /* Body */

  .text-body-lg {
    @apply font-body font-medium text-xl;
  }

  .text-body-md {
    @apply font-body font-medium text-lg;
  }

  .text-body-sm {
    @apply font-body font-medium text-base;
  }

  /* Caption */

  .caption-md {
    @apply font-body font-medium text-sm;
  }

  .caption-sm {
    @apply font-body font-medium text-xs;
  }

  /* Button Text */

  .text-button-lg {
    @apply font-body font-medium text-lg;
  }

  .text-button-md {
    @apply font-body font-medium text-base;
  }

  .text-button-sm {
    @apply font-body font-medium text-sm;
  }
}
